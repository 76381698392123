<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          :onerror="`this.src='${defaultImg}'`"
          alt="Image"
          @click="selectImage"
        ></div>
        <!-- @input="pickFile" -->
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'fullCode'"
            :value="establishmentRole.fullCode"
            v-on:changeValue="establishmentRole.fullCode = $event"
            :title="$t('EstablishmentRoles.code')"
            :imgName="'code.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'userTypeToken'"
            :value="establishmentRole.userTypeToken"
            :options="userTypeTokenOptions"
            v-on:changeValue="establishmentRole.userTypeToken = $event"
            :title="$t('users.selectType')"
            :imgName="'type.svg'"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="'establishmentRoleNameAr'"
            :value="establishmentRole.establishmentRoleNameAr"
            v-on:changeValue="
              establishmentRole.establishmentRoleNameAr = $event
            "
            :title="$t('EstablishmentRoles.nameAr')"
            :imgName="'establishment-role.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'establishmentRoleNameEn'"
            :value="establishmentRole.establishmentRoleNameEn"
            v-on:changeValue="
              establishmentRole.establishmentRoleNameEn = $event
            "
            :title="$t('EstablishmentRoles.nameEn')"
            :imgName="'establishment-role.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'establishmentRoleDescriptionAr'"
            :value="establishmentRole.establishmentRoleDescriptionAr"
            v-on:changeValue="
              establishmentRole.establishmentRoleDescriptionAr = $event
            "
            :title="$t('EstablishmentRoles.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'establishmentRoleDescriptionEn'"
            :value="establishmentRole.establishmentRoleDescriptionEn"
            v-on:changeValue="
              establishmentRole.establishmentRoleDescriptionEn = $event
            "
            :title="$t('EstablishmentRoles.descriptionEn')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'establishmentRoleDescriptionEn'"
            :value="establishmentRole.establishmentRoleDescriptionEn"
            v-on:changeValue="
              establishmentRole.establishmentRoleDescriptionEn = $event
            "
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="establishmentRole.establishmentRoleDefaultStatus"
            v-on:changeValue="
              establishmentRole.establishmentRoleDefaultStatus = $event
            "
            :title="$t('EstablishmentRoles.defaultStatus')"
          />
        </div>
      </div>

      <div class="my-card">
        <GeneralPrivilege
          :model="establishmentRole.usersPrivilegeActivityTypes"
          :modelName="$t('activityTypes.modelName')"
          :id="'activityTypes'"
        />

        <!-- #region theBrands -->
        <b-button
          v-b-toggle="'theBrandsPrivilege'"
          class="btn btn-lg btn-collapse"
        >
          {{ $t("theBrands") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </b-button>
        <b-collapse :id="'theBrandsPrivilege'">
          <div class="my-card">
            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeBrands"
              :modelName="$t('brands.modelName')"
              :id="'brands'"
            />

            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeBrandServices"
              :modelName="$t('brandServices.modelName')"
              :id="'brandServices'"
            />
            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeBrandPlaces"
              :modelName="$t('brandPlaces.modelName')"
              :id="'brandPlaces'"
            />

            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeBrandUsers"
              :modelName="$t('BrandUsers.modelName')"
              :id="'BrandUsers'"
            />
          </div>
        </b-collapse>
        <!-- #endregion theBrands -->

        <GeneralPrivilege
          :model="establishmentRole.usersPrivilegeSponsors"
          :modelName="$t('Sponsors.modelName')"
          :id="'Sponsors'"
        />
        <GeneralPrivilege
          :model="establishmentRole.usersPrivilegeOffers"
          :modelName="$t('offers')"
          :id="'offers'"
        />

        <!-- #region theCards -->
        <b-button
          v-b-toggle="'theCardsPrivilege'"
          class="btn btn-lg btn-collapse"
        >
          {{ $t("theCards.modelName") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </b-button>
        <b-collapse :id="'theCardsPrivilege'">
          <div class="my-card">
            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeCardCreationPlans"
              :modelName="$t('CardPlans.modelName')"
              :id="'CardPlans'"
            />

            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeCards"
              :modelName="$t('Cards.modelName')"
              :id="'Cards'"
            />

            <PrivilegeSellCards
              :model="establishmentRole.usersPrivilegeSellCards"
              :modelName="$t('SellCards.modelName')"
              :id="'SellCards'"
            />
          </div>
        </b-collapse>
        <!-- #endregion theCards -->

        <!-- #region users -->
        <b-button v-b-toggle="'usersPrivilege'" class="btn btn-lg btn-collapse">
          {{ $t("users.modelName") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </b-button>
        <b-collapse :id="'usersPrivilege'">
          <div class="my-card">
            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeMasterAdmins"
              :modelName="$t('masterAdmins.modelName')"
              :id="'masterAdmins'"
            />
            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeEmployees"
              :modelName="$t('employees.modelName')"
              :id="'employees'"
            />

            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeClients"
              :modelName="$t('clients.modelName')"
              :id="'clients'"
            />
            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeSeller"
              :modelName="$t('sellers.modelName')"
              :id="'sellers'"
            />
          </div>
        </b-collapse>
        <!-- #endregion users -->

        <!-- #region Reports -->
        <PrivilegeReports
          :model="establishmentRole"
          :modelName="$t('reports')"
          :id="'Reports'"
        />
        <!-- #endregion Reports -->

        <!-- #region settings -->
        <b-button
          v-b-toggle="'settingsPrivilege'"
          class="btn btn-lg btn-collapse"
        >
          {{ $t("settings") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </b-button>
        <b-collapse :id="'settingsPrivilege'">
          <div class="my-card">
            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeEstablishmentRoles"
              :modelName="$t('EstablishmentRoles.modelName')"
              :id="'EstablishmentRoles'"
            />
            <GeneralPrivilege
              :model="establishmentRole.usersPrivilegeUserValidSetting"
              :modelName="$t('UserValidSettings.modelName')"
              :id="'UserValidSettings'"
            />
          </div>
        </b-collapse>
        <!-- #endregion settings -->

        <PrivilegeQrCodeSessions
          :model="establishmentRole.usersPrivilegeQrCodeSessions"
          :modelName="$t('QrCodeSessions.modelName')"
          :id="'QrCodeSessions'"
        />
        <GeneralPrivilege
          :model="establishmentRole.usersPrivilegeQrCodeSessionsPurchases"
          :modelName="$t('QrCodeSessionsPurchases.modelName')"
          :id="'QrCodeSessionsPurchases'"
        />

        <PrivilegeNotifications
          :model="establishmentRole.usersPrivilegeNotifications"
          :modelName="$t('notifications.modelName')"
          :id="'notifications'"
        />
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateEstablishmentRole"
        >
          {{ submitName }}
        </button>
        <router-link
          :to="{ name: 'EstablishmentRoles' }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { setDataMultiLang } from "@/utils/functions";
import EstablishmentRoleImg from "@/assets/images/establishment-role.svg";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import TextArea from "@/components/general/TextArea.vue";
import GeneralPrivilege from "./privileges/GeneralPrivilege.vue";
import PrivilegeSellCards from "./privileges/PrivilegeSellCards.vue";
import PrivilegeReports from "./privileges/PrivilegeReports.vue";
import PrivilegeQrCodeSessions from "./privileges/PrivilegeQrCodeSessions.vue";
import PrivilegeNotifications from "./privileges/PrivilegeNotifications.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomSelectBox,
    CustomCheckbox,
    TextArea,
    GeneralPrivilege,
    PrivilegeSellCards,
    PrivilegeReports,
    PrivilegeQrCodeSessions,
    PrivilegeNotifications,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      defaultImg: EstablishmentRoleImg,
      userTypeTokenOptions: [],
    };
  },
  props: ["establishmentRole", "imageSrc", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.establishmentRole.mediaFile = file[0];
      } else {
        this.imageSrc = EstablishmentRoleImg;
        this.establishmentRole.mediaFile = "";
      }
    },
    async addOrUpdateEstablishmentRole() {
      this.$emit("addOrUpdateEstablishmentRole");
    },
    async getDialogOfUserTypes() {
      this.isLoading = true;
      this.userTypeTokenOptions = [];
      this.userTypeTokenOptions.push({
        value: "",
        text: this.$t("users.selectType"),
      });
      let genders = this.constantsListsData.listUserType;
      for (let item in genders) {
        this.userTypeTokenOptions.push({
          value: genders[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
  },
  watch: {},
  async created() {
    this.getDialogOfUserTypes();
  },
};
</script>

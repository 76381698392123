<template>
  <div class="form-label-group mt-2" :class="className">
    <b-form-checkbox v-model="theValue" name="check-button" switch>
      {{ title ? title : $t("notFound") }}
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: "CustomCheckbox",
  data() {
    return {
      theValue: this.value,
    };
  },
  methods: {},
  props: {
    className: {
      type: String,
      default: "",
    },
    value: {
      default: "",
    },
    title: {
      type: String,
      default: "title",
    },
  },
  watch: {
    theValue: function (val) {
      if (val != this.value) this.$emit("changeValue", val);
    },
    value: function (val) {
      this.theValue = val;
    },
  },
  async created() {},
};
</script>
